export const environment = {
    production: true,
    api: {
        messengerApiBaseUrl: 'https://xng-dev-api-messenger-ca.grayforest-dac05fd2.westeurope.azurecontainerapps.io/',
        tenantPortalApiBaseUrl: 'https://xng-dev-api-portal-ca.grayforest-dac05fd2.westeurope.azurecontainerapps.io/',
    },
    b2c: {
        clientID: '5c6101ba-fb0e-452b-b49b-a3f7baeee3b6',
        b2cPolicy: 'B2C_1_SignInDefaultFlow',
        authorityUrl: 'https://xngdevb2c.b2clogin.com/xngdevb2c.onmicrosoft.com',
        authorityDomain: 'xngdevb2c.b2clogin.com',
        redirectUri: 'https://www.x-engines.com/'
    },
};

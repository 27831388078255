import { NgModule } from '@angular/core';
import {
    HashLocationStrategy,
    LocationStrategy,
    PathLocationStrategy,
} from '@angular/common';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AppLayoutModule } from './layout/app.layout.module';
import { NotfoundComponent } from './core/notfound/notfound.component';
import { ProductService } from './sk-demo/service/product.service';
import { CountryService } from './sk-demo/service/country.service';
import { CustomerService } from './sk-demo/service/customer.service';
import { EventService } from './sk-demo/service/event.service';
import { IconService } from './sk-demo/service/icon.service';
import { NodeService } from './sk-demo/service/node.service';
import { PhotoService } from './sk-demo/service/photo.service';
import { PipesModule } from './core/pipes/pipes.module';

import { RouterLink} from '@angular/router';
import { RouterOutlet } from '@angular/router';
import { CommonModule } from '@angular/common';

import { provideHttpClient, withInterceptorsFromDi, HTTP_INTERCEPTORS, withFetch } from '@angular/common/http';

import { msalConfig, protectedResources } from './auth.config';

import {
    IPublicClientApplication,
    PublicClientApplication,
    InteractionType,
} from '@azure/msal-browser';

import {
    MsalInterceptor,
    MSAL_INSTANCE,
    MsalInterceptorConfiguration,
    MsalGuardConfiguration,
    MSAL_GUARD_CONFIG,
    MSAL_INTERCEPTOR_CONFIG,
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    MsalModule
} from '@azure/msal-angular';

export function MSALInstanceFactory(): IPublicClientApplication {
    console.log('msalConfig', msalConfig);
    return new PublicClientApplication(msalConfig);
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>();
    
    // todo add more protected resources
    protectedResourceMap.set(protectedResources.apiConfig.uri, protectedResources.apiConfig.scopes);

    console.log('protectedResourceMap', protectedResourceMap);

    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap
    };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect
    };
}

@NgModule({
    declarations: [AppComponent, NotfoundComponent],
    imports: [AppRoutingModule, AppLayoutModule, CommonModule, MsalModule, RouterOutlet, RouterLink],
    exports: [PipesModule],
    providers: [
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        { provide: LocationStrategy, useClass: PathLocationStrategy },
        CountryService,
        CustomerService,
        EventService,
        IconService,
        NodeService,
        PhotoService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }

import { MenuItem } from 'primeng/api';
import {
    Component,
    ElementRef,
    Inject,
    OnInit,
    OnDestroy,
    ViewChild,
} from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import {
    MsalService,
    MsalModule,
    MsalBroadcastService,
    MSAL_GUARD_CONFIG,
    MsalGuardConfiguration,
} from '@azure/msal-angular';
import {
    AuthenticationResult,
    InteractionStatus,
    PopupRequest,
    RedirectRequest,
    EventMessage,
    EventType,
    AccountInfo,
} from '@azure/msal-browser';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

type ProfileType = {
    givenName?: string;
    surname?: string;
    userPrincipalName?: string;
    id?: string;
};

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
})
export class AppTopBarComponent implements OnInit, OnDestroy {
    private readonly _destroying$ = new Subject<void>();
    loginDisplay = false;

    items!: MenuItem[];

    profile: ProfileType | undefined;

    @ViewChild('menubutton') menuButton!: ElementRef;

    @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;

    @ViewChild('topbarmenu') menu!: ElementRef;

    constructor(
        @Inject(MSAL_GUARD_CONFIG)
        private msalGuardConfig: MsalGuardConfiguration,
        private authService: MsalService,
        private msalBroadcastService: MsalBroadcastService,
        public layoutService: LayoutService,
        private http: HttpClient,
        public el: ElementRef
    ) {}

    ngOnInit(): void {
        this.authService.handleRedirectObservable().subscribe();

        this.msalBroadcastService.msalSubject$
            .pipe(
                filter(
                    (msg: EventMessage) =>
                        msg.eventType === EventType.ACCOUNT_ADDED ||
                        msg.eventType === EventType.ACCOUNT_REMOVED ||
                        msg.eventType === EventType.LOGIN_SUCCESS
                )
            )
            .subscribe((result: EventMessage) => {
                if (this.authService.instance.getAllAccounts().length === 0) {
                    window.location.pathname = '/';
                } else {
                    this.setLoginDisplay();
                }
            });

        this.msalBroadcastService.inProgress$
            .pipe(
                filter(
                    (status: InteractionStatus) =>
                        status === InteractionStatus.None
                ),
                takeUntil(this._destroying$)
            )
            .subscribe(() => {
                this.setLoginDisplay();
                this.checkAndSetActiveAccount();
                // this.getProfile();
            });
    }

    setLoginDisplay() {
        this.loginDisplay =
            this.authService.instance.getAllAccounts().length > 0;
    }

    checkAndSetActiveAccount() {
        /**
         * If no active account set but there are accounts signed in, sets first account to active account
         * To use active account set here, subscribe to inProgress$ first in your component
         * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
         */
        let activeAccount = this.authService.instance.getActiveAccount();

        if (
            !activeAccount &&
            this.authService.instance.getAllAccounts().length > 0
        ) {
            let accounts = this.authService.instance.getAllAccounts();
            this.authService.instance.setActiveAccount(accounts[0]);
            console.log('set account', accounts[0]);
        } else {
            console.log('account', activeAccount);
        }
    }

    // todo: remove this method
    getProfile() {
        this.http
            .get('https://graph.microsoft-ppe.com/v1.0/me')
            .subscribe((profile) => {
                this.profile = profile;
                console.log('profile', profile);
            });
    }

    loginRedirect() {
        if (this.msalGuardConfig.authRequest) {
            this.authService.loginRedirect({
                ...this.msalGuardConfig.authRequest,
            } as RedirectRequest);
        } else {
            this.authService.loginRedirect();
        }
    }

    logout(popup?: boolean) {
        if (popup) {
            this.authService.logoutPopup({
                mainWindowRedirectUri: '/',
            });
        } else {
            this.authService.logoutRedirect();
        }
    }

    handleLoginLogout() {
        if (this.loginDisplay) {
            this.logout();
        } else {
            this.loginRedirect();
        }
    }

    ngOnDestroy(): void {
        this._destroying$.next(undefined);
        this._destroying$.complete();
    }
}

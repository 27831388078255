import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { LayoutService } from './service/app.layout.service';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html',
})
export class AppMenuComponent implements OnInit {
    model: any[] = [];

    constructor(public layoutService: LayoutService) {}

    ngOnInit() {
        this.model = [
            {
                label: 'Home',
                items: [
                    {
                        label: 'Dashboard',
                        icon: 'pi pi-fw pi-home',
                        routerLink: ['/'],
                    },
                    {
                        label: 'Marketing app',
                        icon: 'pi pi-fw pi-globe',
                        url: ['https://www.xengines.ai/'], target: '_blank'
                    },
                    // {
                    //     label: 'Marketing app',
                    //     icon: 'pi pi-fw pi-globe',
                    //     routerLink: ['/landing'],
                    // },
                ],
            },
            // {
            //     label: 'Pages (will be removed)',
            //     icon: 'pi pi-fw pi-briefcase',
            //     items: [
            //         // {
            //         //     label: 'Marketing app',
            //         //     icon: 'pi pi-fw pi-globe',
            //         //     routerLink: ['/landing'],
            //         // },
            //         // {
            //         //     label: 'Login',
            //         //     icon: 'pi pi-fw pi-sign-in',
            //         //     routerLink: ['/auth/login']
            //         // },
            //         // {
            //         //     label: 'Error',
            //         //     icon: 'pi pi-fw pi-times-circle',
            //         //     routerLink: ['/auth/error']
            //         // },
            //         // {
            //         //     label: 'Access Denied',
            //         //     icon: 'pi pi-fw pi-lock',
            //         //     routerLink: ['/auth/access']
            //         // },
            //         // {
            //         //     label: 'Not Found',
            //         //     icon: 'pi pi-fw pi-exclamation-circle',
            //         //     routerLink: ['/notfound']
            //         // },
            //         // {
            //         //     label: 'Empty',
            //         //     icon: 'pi pi-fw pi-circle-off',
            //         //     routerLink: ['/pages/empty']
            //         // },
            //         {
            //             label: 'Crud',
            //             icon: 'pi pi-fw pi-pencil',
            //             routerLink: ['/pages/crud']
            //         },
            //     ],
            // },
        ];
    }
}
